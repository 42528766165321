// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const api_key_tap_web = '8U9ezbHvfw5G4FX0ZnXvnRE0z5KKN5C5XaEOCna5';
export const totalReferrals = 2;

export const environment = {
    production: false,
    ambiente: 'qa',
    first: 0,
    rows: 5,
    apiVeridas: 'https://xpressid-web-work.eu.veri-das.com/v2/?access_token=',
    apiKey_registrarCliente: api_key_tap_web,
    apiKey_validarOTPSms: api_key_tap_web,
    apiKey_getAccessTokenVeridas: api_key_tap_web,
    apiKey_registrarEmail: api_key_tap_web,
    apiKey_validarOtpEmail: api_key_tap_web,
    apiKey_getCatalogos: api_key_tap_web,
    apiKey_sendDatosClientes: api_key_tap_web,
    apiKey_sendValidaOCR: api_key_tap_web,
    apiKey_reenviarSMS: api_key_tap_web,
    apiKey_sendOTPVerificacion: api_key_tap_web,
    apiKey_motorPago: api_key_tap_web,
    apiKey_getPreGuardado: api_key_tap_web,
    apiKey_getInfoCliente: api_key_tap_web,
    apiKey_refreshToken: api_key_tap_web,
    apiKey_getCP: api_key_tap_web,
    apiKey_retomarTramiteSms: api_key_tap_web,
    apiKey_SolicitaCuenta: api_key_tap_web,
    apiKey_emailEdge: api_key_tap_web,
    apiKey_evaluador: api_key_tap_web,
    apiKey_severityLevel: api_key_tap_web,
    apiKey_consultaRecurso: api_key_tap_web,
    apiKey_reservaRecurso: api_key_tap_web,
    apiKey_cancelarReseva: api_key_tap_web,
    apiKey_cotizacion: api_key_tap_web,
    apiKey_generarNota: api_key_tap_web,
    apiKey_generarContrato: api_key_tap_web,
    apiKey_motorCalculo: api_key_tap_web,
    apiKey_scoreTelco: api_key_tap_web,
    apiKey_postPrestamos: api_key_tap_web,
    apiKey_putSolicitud: api_key_tap_web,
    apiKey_getSolicitud: api_key_tap_web,
    apiKey_consultaCitas: api_key_tap_web,
    apiKey_reservaCitas:api_key_tap_web,
    apiKey_cancelaCitas:api_key_tap_web,
    apiKey_generarCitaEntrega:api_key_tap_web,
    apiKey_cancelaCitaEntrega:api_key_tap_web,
    apiKey_actualizaPasos:api_key_tap_web,
    apiKey_enviaCorreo: api_key_tap_web,
    apiKey_registrarEnvio: api_key_tap_web,
    apiKey_getLogLambda: api_key_tap_web,
    apiKey_registroLogLambda: api_key_tap_web,
    apiKey_sessionTagGet: api_key_tap_web,
    apiKey_sessionRenapo: api_key_tap_web,
    apiKey_ValidUrl: api_key_tap_web,
    apiKey_conekta: api_key_tap_web,
    apiKey_asurion: api_key_tap_web,


  apiKey_getEnvioEquipos: api_key_tap_web,
  apiKey_cacsSmt: api_key_tap_web,
  apiKey_actualizarStatusCliente: api_key_tap_web,
  apiKey_reservaImei: api_key_tap_web,
  apiKey_registrarDispositivo: api_key_tap_web,
  apiKey_emailCheck: api_key_tap_web,
  apiKey_revisionIne: api_key_tap_web,
  apiKey_terminaFlujo: api_key_tap_web,
  apiKey_settings: api_key_tap_web,
  apiKey_sicatelGetOrdenPago: api_key_tap_web,
  apiKey_sicatelGenerarOrdenPago: api_key_tap_web,
  apiKey_tramiteFuturo: api_key_tap_web,
};

export const amazon = {
    production: false,
    apiUrlAmazon: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com',
    //apiUrlDrupal: 'https://ec2-184-72-154-197.compute-1.amazonaws.com',
    apiUrlDrupal: 'https://54.90.28.102',
    first: 0,
    rows: 5
};



export const nauphilusCredentials = {
  membershipKey: 'SELFQA',
  apiClientID: 'selfQAwidget@nauphilusmail.com',
  apiClientSecretKey: 'TeLc3LQA24',
  idProspect: '',
  productKey: 'PBAREP1'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
